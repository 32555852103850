import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
import { AuthenService } from '../../services/authen.service';
import { HttpService } from '../../services/http.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public loading = false;
  public UserName = '';
  constructor(private titleService: Title,
    private httpService: HttpService) {
    this.titleService.setTitle(`Home page - ${environment.APP_TITLE}`);
    this.UserName = AuthenService.getDisplayName();

    // call check authorize server
    this.loading = !this.loading;
    this.httpService.DoGet(environment.APP_API_URL + '/api/home').subscribe();
  }

  ngOnInit() {
  }

}
