// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  APP_TITLE: 'AP Log System (Development)',
  APP_CLIENT_URL: 'http://localhost:4200',
  APP_API_URL: 'https://localhost:5001',
  loginSSO: false,
  loginUrl: 'http://localhost:4200/login',
};


// export const environment = {
//   production: true,
//   APP_TITLE: 'AP Logging',
//   APP_CLIENT_URL: 'http://172.16.0.50:6064',
//   APP_API_URL: 'http://172.16.0.50:6065',
//   loginSSO: false,
//   loginUrl: 'http://172.16.0.50:6064/login',
// };


// export const environment = {
//   production: true,
//   APP_TITLE: 'AP Logging',
//   APP_CLIENT_URL: 'https://aplogmonitor.nextgen-global.com',
//   APP_API_URL: 'https://aplogmonitor.api.nextgen-global.com',
//   loginSSO: false,
//   loginUrl: 'https://aplogmonitor.nextgen-global.com/login',
// };
