// Angular
import { Component, TemplateRef } from '@angular/core';
import { ngxLoadingAnimationTypes } from 'ngx-loading';

// Login
import { UserLoginModel } from '../../models/UserLoginModel';
import { AuthenService } from '../../services/authen.service';
import { Title } from '@angular/platform-browser';
import { environment } from '../../../environments/environment';
const PrimaryWhite = '#ffffff';

@Component({
  selector: 'app-dashboard',
  templateUrl: 'login.component.html'
})
export class LoginComponent {
  userLoginModel: UserLoginModel;
  public loadingTemplate: TemplateRef<any>;
  public primaryColour = PrimaryWhite;
  public ngxLoadingAnimationTypes = ngxLoadingAnimationTypes;

  constructor(public authenService: AuthenService,
    private titleService: Title,
    ) {
      this.titleService.setTitle(`Login - ${environment.APP_TITLE}`);
    this.userLoginModel = new UserLoginModel();
    setTimeout(() => {
      this.authenService.tryReload();
    }, 1000);
  }

  public login() {
    this.authenService.doLogin(this.userLoginModel).subscribe();
  }
  return() {
    return;
  }
}
